import { observable } from 'mobx';
import { Model, Store, Casts } from 'store/Base';
import { MultiFactorAuthentication } from './MultiFactorAuthentication';

export class Authentication extends Model {
    static backendResourceName = 'authentication';

    @observable id = null;

    @observable status = 'no_mfa';
    @observable userAgent = '';
    @observable ipAddress = '';
    @observable code = null;
    @observable verificationExpiresAt = null;
    @observable codeExpiresAt = null;
    @observable method = '';
    @observable activated = false;
    @observable mfa = this.relation(MultiFactorAuthentication);

    casts() {
        return {
            verificationExpiresAt: Casts.datetime,
            codeExpiresAt: Casts.datetime,
        };
    }
}

export class AuthenticationStore extends Store {
    Model = Authentication;
    static backendResourceName = 'authentication';

    verifyCode(code, secretKey) {
        return this.wrapPendingRequestCount(this.api.post(`${this.url()}verify/`, { code, secret_key: secretKey }, { supressError: true }));
    }

    generateSecret() {
        return this.wrapPendingRequestCount(this.api.get(`${this.url()}generate_secret/`));
    }

    generateQr(secretKey) {
        return this.wrapPendingRequestCount(this.api.post(`${this.url()}generate_qr/`, { secret_key: secretKey }, { supressError: true, responseType: 'blob' }));
    }

    resetCode() {
        return this.wrapPendingRequestCount(this.api.post(`${this.url()}reset_code/`));
    }
}
