import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button, TopMenu, MenuRow as BaseMenuRow, NavMenu, NavItem, Logo } from 're-cy-cle';
import { Header, Modal, Icon } from 'semantic-ui-react';
import { Route, withRouter } from 'react-router-dom';
import ImgLogo from 'image/logo.png';
import { SmallAvatar } from 'component/UserAvatar';
import { BUILD_INFO } from 'helpers';
import { ModuleRepository } from '_iae/module/repository';
import styled from 'styled-components';

const MyLogo = () => (
    <Logo>
        <img src={ImgLogo} height="35" alt="logo"/>
    </Logo>
);

const MenuRow = styled(BaseMenuRow)`
    ${({ theme }) => theme.styledTopMenu && `
        background-color: ${theme.toolbarColor};
        color: #FFF;

        .nav-item:before {
            border-bottom-color: ${theme.primaryColor};
        }

        &:nth-child(even) {
            background-color: ${theme.toolbarColor};
            color: ${theme.primaryColor};
        }
    `}
`;

@withRouter
@observer
export default class AppHeader extends Component {
    static propTypes = {
        store: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired,
    };

    @observable debug = false;
    @observable showLogoutModal = false;

    toggleDebug = () => {
        this.debug = !this.debug;

        if (this.debug) {
            localStorage.setItem('debug', true);
        } else {
            localStorage.removeItem('debug');
        }
    }

    constructor(...args) {
        super(...args);
        this.debug = !!localStorage.getItem('debug');
    }

    hasPermission = perms => {
        return this.props.store.currentUser.hasPermission(perms);
    };

    hasOneOfGroups = groups => {
        // Create a list of all groups of the user
        const userGroups = this.props.store.currentUser.groupNames;

        // Map over the array, checking if at least one of the user's groups
        // overlaps with the given list of groups
        return userGroups.some(userGroup => groups.includes(userGroup));
    }

    renderAssets = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.assets.customers')}
                    to="/assets/customer/overview"
                    activePath="/assets/customer/"
                />
                <NavItem
                    title={t('nav.assets.crops')}
                    to="/assets/crop/overview"
                    activePath="/assets/crop/"
                />
                <NavItem
                    title={t('nav.planning.packagingLines')}
                    to="/assets/packaging-line/overview"
                    activePath="/assets/packaging-line/"
                />
                <NavItem
                    title={t('nav.assets.users')}
                    to="/assets/user/overview"
                    activePath="/assets/user/"
                />
                <NavItem
                    title={t('nav.assets.globalValues')}
                    to="/assets/global-value/overview"
                    activePath="/assets/global-value/"
                />
                <NavItem
                    title={t('nav.assets.globalFiles')}
                    to="/assets/global-file/overview"
                    activePath="/assets/global-file/"
                />
                <NavItem
                    title={t('nav.assets.emailTemplates')}
                    to="/assets/email-template/overview"
                    activePath="/assets/email-template/"
                />
            </NavMenu>
        );
    };

    renderAudit = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.audit.accessLog')}
                    to="/audit/access-log/overview"
                    activePath="/audit/access-log/"
                />
            </NavMenu>
        );
    };

    renderPlanning = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.planning.main')}
                    to="/planning/main/overview"
                    activePath="/planning/main/"
                />
                <NavItem
                    title={t('nav.planning.salesOrders')}
                    to="/planning/sales-order/overview"
                    activePath="/planning/sales-order/"
                />
            </NavMenu>
        );
    };

    renderAccountMenu = () => {
        const { store } = this.props;
        const { version, branch } = BUILD_INFO;

        return (
            <NavItem
                title={
                    <span data-test-user-details>
                        <SmallAvatar user={store.currentUser}/>
                        {' '}
                        {store.currentUser.fullName} (
                        {branch && branch !== 'production' && branch + ' '}
                        {version}
                        )
                    </span>
                }
                to="/account/details"
                activePath="/account/"
            />
        );
    };

    renderAccount = () => {
        return (
            <NavMenu>
                <NavItem
                    title={t('nav.account.account')}
                    to="/account/details"
                />
                <React.Fragment>
                    <NavItem title={t('nav.account.changelog')} to="/account/changelog"/>
                </React.Fragment>
            </NavMenu>
        );
    };

    render() {
        const { currentUser } = this.props.store;
        const { moduleRepository } = this.props;

        if (!this.props.store.isAuthenticated || (
            currentUser.groupNames === undefined &&
            !currentUser.isSuperuser
        )) {
            return (
                <TopMenu>
                    <MenuRow>
                        <MyLogo/>
                    </MenuRow>
                    <MenuRow/>
                </TopMenu>
            );
        }

        const logoutModal = (
            <Modal closeIcon open={this.showLogoutModal} onClose={() => this.showLogoutModal = false} basic
                   size='small'>
                <Header icon='archive' content={t('user.account.logoutButton')}/>
                <Modal.Content>
                    <p>
                        {t('user.account.logout.confirm')}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red' inverted onClick={() => this.showLogoutModal = false}>
                        <Icon name='remove'/> {t('form.no')}
                    </Button>
                    <Button color='green' inverted onClick={() => {
                        this.props.store.performLogout().then(() => {
                            this.showLogoutModal = false;
                        });
                    }}>
                        <Icon name='checkmark'/> {t('form.yes')}
                    </Button>
                </Modal.Actions>
            </Modal>
        );

        let menu = currentUser.isSuperuser ? (
            <TopMenu>
                <MenuRow>
                    <MyLogo/>
                    <NavMenu>
                        <NavItem
                            title={t('nav.assets.users')}
                            to="/assets/user/overview"
                            activePath="/assets/user/"
                        />
                        <NavItem
                            title={t('nav.audit.accessLog')}
                            to="/audit/access-log/overview"
                            activePath="/audit/access-log/"
                        />
                        {moduleRepository.modules.map(module => {
                            return module.navigationMenu?.()?.topMenuItem;
                        })}
                    </NavMenu>
                    {this.renderAccountMenu()}
                </MenuRow>
                <MenuRow>
                    <Route path="/account" render={this.renderAccount}/>
                    {/* Hackity hack hack to always show second menu bar */}
                    <NavItem title=" " to="/" activePath="/neverusethispath"/>
                </MenuRow>
            </TopMenu>
        ) : (
            <TopMenu>
                <MenuRow>
                    <MyLogo/>
                    <NavMenu>
                        <NavItem
                            title={t('nav.main.planning')}
                            to="/planning/main/overview"
                            activePath="/planning/"
                        />
                        {(currentUser.isSuperuser || this.hasOneOfGroups(['admin', 'team_lead', 'planner'])) && (<NavItem
                            title={t('nav.main.assets')}
                            to="/assets/user/overview"
                            activePath="/assets/"
                        />)}
                    </NavMenu>
                    {this.renderAccountMenu()}
                </MenuRow>
                <MenuRow data-test-system-navbar>
                    <Route path="/account" render={this.renderAccount}/>
                    <Route path="/planning/" render={this.renderPlanning}/>
                    <Route path="/assets/" render={this.renderAssets}/>
                    <Route path="/audit/" render={this.renderAudit}/>
                </MenuRow>
            </TopMenu>
        );

        return (
            <div data-test-app-header>
                {menu}
                {logoutModal}
            </div>
        );
    }
}
