import { tint } from 'polished';
import { IS_STAGE, IS_UAT } from 'helpers';

// Change the colors for your personal preference
const COLOR_PRIMARY_PROD = '#11933A';
const COLOR_PRIMARY_STAGE = '#2abda5';
const COLOR_PRIMARY_UAT = '#96bd0d';

const getPrimaryColor = (): string => {
    if (IS_STAGE) return COLOR_PRIMARY_STAGE;
    if (IS_UAT) return COLOR_PRIMARY_UAT;
    return COLOR_PRIMARY_PROD;
};

const primaryColor = getPrimaryColor();
const tintedPrimaryColor = tint(0.15, primaryColor);
const buttonColor = getPrimaryColor();
const dangerColor = '#dc0818';
const warningColor = '#ffc107';
const toolbarColor = '#e0e0e0';

export const theme = {
    primaryColor,
    tintedPrimaryColor,
    buttonColor,
    dangerColor,
    warningColor,
    toolbarColor,
};
